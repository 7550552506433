import {Component, OnInit} from '@angular/core';
import {WsAccountingDataSearch, WsCustomerDocumentFileSortField, WsCustomerDocumentGroupField, WsCustomerDocumentSearch, WsCustomerDocumentStatus, WsRefWsTrustee} from "@fiscalteam/nitro-domain-client";
import {CustomerDocumentService, DataCustomerRow, DocumentGroupSummaryRow, NitroLoggedUserService, PaginationUtils, ResultPage} from "@fiscalteam/ngx-nitro-services";
import {Observable, of, switchMap} from "rxjs";
import {WorkListService} from "../../service/work-list-service";
import {Router} from "@angular/router";
import {CustomerDocumentWorklistTableHelper} from "../document-list/customer-document-worklist-table-helper";
import {AsyncPipe, NgForOf, NgIf} from "@angular/common";
import {Button, ButtonDirective} from "primeng/button";
import {Tag} from "primeng/tag";

@Component({
  selector: 'idx-customer-list',
  templateUrl: './customer-list.component.html',
  styleUrl: './customer-list.component.scss',
  standalone: true,
  imports: [
    NgIf,
    AsyncPipe,
    Button,
    Tag,
    ButtonDirective,
    NgForOf
  ],
  providers: [
    CustomerDocumentWorklistTableHelper,
  ]
})
export class CustomerListComponent implements OnInit {
  trusteeRef = this.nitroLoggedUserService.getActiveTrusteeRef() as WsRefWsTrustee;
  dataCustomerRow$?: Observable<DataCustomerRow[]>;
  protected readonly String = String;

  constructor(
    private nitroLoggedUserService: NitroLoggedUserService,
    private customerDocumentService: CustomerDocumentService,
    private workListService: WorkListService,
    private router: Router
  ) {
  }

  ngOnInit(): void {
    this.dataCustomerRow$ = this.getCustomerlist$();
    this.getCustomerlist$().subscribe()
  }

  getCustomerDocumentsRouterLink(customerId: string | undefined): any {
    if (customerId) {
      const filter = this.workListService.createCustomerWorkListFilter(customerId);
      this.workListService.initNewWorkList(filter)
      this.router.navigate(['/list']);
    }
  }

  getCustomerlist$() {
    const docSearch: WsCustomerDocumentSearch = {
      accountingDataSearch: {
        customerSearch: {
          trusteeSearch: {
            exactTrusteeWsRef: this.trusteeRef,
          }
        }
      },
      anyStatus: [
        WsCustomerDocumentStatus.Sortable,
        WsCustomerDocumentStatus.Sorted,
      ]
    };
    const pagination = PaginationUtils.newPagination(0, 100, [{
      field: WsCustomerDocumentFileSortField.GroupCount,
      order: 'desc'
    }]);
    pagination.group = WsCustomerDocumentGroupField.Customer;

    return this.customerDocumentService.searchDocumentGroupSummaries$(docSearch, pagination).pipe(
      switchMap(results => this.createDocumentSummaries$(docSearch, results)),
    )

  }

  private createDocumentSummaries$(dataSearch: WsAccountingDataSearch, results: ResultPage<DocumentGroupSummaryRow>): Observable<DataCustomerRow[]> {
    const items = results.itemList || [];
    const rows: DataCustomerRow[] = [];

    items.forEach((groupItem: DocumentGroupSummaryRow) => {
      const groupId = groupItem.groupId;
      const groupCustomer = groupId;
      const row: DataCustomerRow = {
        searchFilter: dataSearch,
        loading: false,
        customerSummaries: this.createDocumentSummary$(groupCustomer, groupItem)
      };
      rows.push(row);
    });
    return of(rows);
  }

  private createDocumentSummary$(groupCustomer: string, groupItem: DocumentGroupSummaryRow) {
    return {
      groupId: groupCustomer,
      groupTotalCount: groupItem.documentCount,
      groupProblematicCount: groupItem.problematicDocumentCount,
      groupCustomerName: groupItem.customer?.label
    };
  }
}


