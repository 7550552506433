import {Component, OnDestroy, OnInit} from '@angular/core';
import {Router, RouterOutlet} from "@angular/router";
import {WsCustomerDocument} from "@fiscalteam/nitro-domain-client";
import {EMPTY, Observable, of, shareReplay, Subscription, switchMap, take, delay} from "rxjs";
import {catchError, debounceTime, map, tap} from "rxjs/operators";
import {IndexerFrontConfigService} from "../../config/indexer-front-config.service";
import {WorkListService} from "../../service/work-list-service";
import {WorkListNavigationStateService} from "../../service/work-list-navigation-state.service";
import {CustomerDocumentService} from "@fiscalteam/ngx-nitro-services";
import {AsyncPipe, NgIf, NgStyle} from "@angular/common";
import {NgxExtendedPdfViewerModule} from "ngx-extended-pdf-viewer";
import {ToggleButton} from "primeng/togglebutton";
import {FormsModule} from "@angular/forms";
import {ButtonDirective} from "primeng/button";
import {ThemeService} from "../theme-service";
import {FocusTrap} from "primeng/focustrap";

@Component({
  selector: 'idx-document-details',
  templateUrl: './document-details.component.html',
  styleUrls: ['./document-details.component.scss'],
  imports: [
    NgIf,
    AsyncPipe,
    NgStyle,
    NgxExtendedPdfViewerModule,
    ToggleButton,
    RouterOutlet,
    FormsModule,
    ButtonDirective,
    FocusTrap
  ],
  providers:[IndexerFrontConfigService],
  standalone: true
})
export class DocumentDetailsComponent implements OnInit, OnDestroy {

  document$: Observable<WsCustomerDocument | undefined> = EMPTY;
  workItemBlob$: Observable<Blob | undefined> = EMPTY;

  subscription: Subscription = new Subscription();
  workItemHtml$: Observable<string | undefined> = EMPTY;
  htmlMode: boolean = false;
  showButtonCommunication: boolean = false;
  checked: boolean = false;
  theme$: Observable<'light' | 'dark'> = EMPTY;

  constructor(
    private router: Router,
    private workListService: WorkListService,
    private workListNavigationStateService: WorkListNavigationStateService,
    private configService: IndexerFrontConfigService,
    private customerDocumentService: CustomerDocumentService,
    private themeService: ThemeService,
  ) {
    this.theme$ = this.themeService.selectedTheme$;
  }

  ngOnInit(): void {
    const workListItem$ = this.workListService.getWorkListItem$().pipe(
      debounceTime(100),
      shareReplay({bufferSize: 1, refCount: true})
    );
    this.document$ = workListItem$.pipe(
      map(item => item?.document),
      tap(item => {
        if (item && item.id) {
          this.checkForCustomerNotesFile$(item.id).subscribe((hasCustomerNotesFile: boolean) => {
            this.showButtonCommunication = hasCustomerNotesFile;
          });
        } else {
          this.showButtonCommunication = false;
        }
      }),
      shareReplay({bufferSize: 1, refCount: true})
    );
    this.workItemBlob$ = workListItem$.pipe(
      switchMap(item => item?.docBytesBlob$ || of(undefined)),
      shareReplay({bufferSize: 1, refCount: true}),
    );
    this.subscription = new Subscription();
    const navigateOnDocCHangeSubscription = workListItem$.pipe(
    ).subscribe({
      next: i => {
        if (i && i.id != null) {
          this.router.navigate(['/', i.id], {});
        }
      }
    });
    this.subscription.add(navigateOnDocCHangeSubscription);

    if (this.configService.isFeatureEnabled("document.worklist.preload")) {
      const preloadSubscription = this.workListNavigationStateService.subscribeToPreloadNextItemAhead(3)
      this.subscription.add(preloadSubscription);
    }

    this.document$.subscribe(document => {
      if (!document) {
        this.workListService.getActiveWorkListFilter$().pipe(
          take(1),
          delay(1500)
      ).subscribe(filter => {
          this.reloadWorkList()
        });
      }
    });
  }

  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  reloadWorkList() {
    this.workListService.getActiveWorkListFilter$().pipe(
      take(1)
    ).subscribe(filter => this.workListService.initNewWorkList(filter));
  }

  loadHtmlContent() {
    if (this.checked) {
      this.htmlMode = true;
      const workListItem$ = this.workListService.getWorkListItem$();
      this.workItemHtml$ = workListItem$.pipe(
        switchMap(item => {
          if (item && item.document) {
            return this.customerDocumentService.getCustomerDocumentHtml$({id: item.document.id!}).pipe(
              switchMap(blob => this.blobToString(blob)),
              catchError(error => {
                return of(undefined);
              })
            );
          }
          return of(undefined);
        }),
        shareReplay({bufferSize: 1, refCount: true})
      );
    } else {
      this.htmlMode = false;
    }

  }

  private blobToString(blob: Blob): Observable<string> {
    return new Observable<string>(observer => {
      const reader = new FileReader();
      reader.onloadend = () => {
        observer.next(reader.result as string);
        observer.complete();
      };
      reader.onerror = () => {
        observer.error('Failed to read blob as string');
      };
      reader.readAsText(blob);
    });
  }

  checkForCustomerNotesFile$(customerDocumentId: number): Observable<boolean> {
    return this.customerDocumentService.getCustomerDocumentFiles$(customerDocumentId).pipe(
      map(resultPage =>
        resultPage.itemList?.some(
          (file) => file.documentFileType === 'CUSTOMER_NOTES'
        ) || false
      )
    );
  }
}
