import {Injectable} from "@angular/core";
import {BehaviorSubject} from "rxjs";

@Injectable()
export class ThemeService {

  selectedTheme$ = new BehaviorSubject<'light' | 'dark'>('light');


  constructor() {
    const savedTheme = localStorage.getItem('selectedTheme');
    const systemDarkTheme = this.isSystemDarkTheme();

    // Prioritise local storage if it exists
    const initialTheme = savedTheme === 'light' || savedTheme === 'dark'
      ? savedTheme
      : systemDarkTheme ? 'dark' : 'light';
    this.setPageTheme(initialTheme);
  }

  switchDarkTheme() {
    const curTheme = this.selectedTheme$.getValue();
    if (curTheme === "light") {
      this.setPageTheme('dark');
    } else {
      this.setPageTheme('light');
    }
  }

  private isSystemDarkTheme() {
    if (window) {
      return window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches;
    } else {
      return false;
    }
  }

  private setPageTheme(theme: "light" | "dark") {
    //Update localStorage before BehaviorSubject
    localStorage.setItem('selectedTheme', theme);
    this.selectedTheme$.next(theme);
    const doc = document;
    if (doc == null) {
      return;
    }
    const htmlElement = document.documentElement;
    if (theme === "light") {
      htmlElement.classList.remove('speed-dark');
    } else {
      htmlElement.classList.add('speed-dark');
    }
  }
}
