import {Injectable} from "@angular/core";
import {WorkListNavigationStateService} from "./work-list-navigation-state.service";
import {DocumentWorkListFilter, FieldRequestWorkListFilter, WorkListFilter} from "./worklist/work-list-filter";
import {distinctUntilChanged, EMPTY, Observable, switchMap, take} from "rxjs";
import {WorkListItem} from "./worklist/work-list-item";
import {map} from "rxjs/operators";
import {WorkListStateService} from "./work-list-state.service";
import {ParamMap} from "@angular/router";
import {NitroLoggedUserService} from "@fiscalteam/ngx-nitro-services";
import {WsCustomerDocumentSortField, WsCustomerDocumentStatus, WsFieldIdentificationRequestSortField, WsFieldIdentificationRequestStatus, WsFieldIdentificationValueStatus, WsNitroUser, WsRefWsTrustee} from "@fiscalteam/nitro-domain-client";
import {SelectItem} from "primeng/api";


/**
 * A higher-level service for handling navigation through work list of documents to index.
 * When a work list filter is set, the navigation is started and work list items will be emitted.
 * When a document is completed, gotToNextItem() can be called to continue navigation.
 * When navigation is completed, an undefined worklist item will be emitted.
 */
@Injectable({
  providedIn: "root"
})
export class WorkListService {

  constructor(
    private workListStateService: WorkListStateService,
    private workListNavigationStateService: WorkListNavigationStateService,
    private loggedUserService: NitroLoggedUserService,
  ) {
  }

  /**
   * Initialize a work list on application startup.
   *
   * Depending on the parameters in the route, different work lists might be initialized.
   * @param routeParams
   */
  initStartWorkList(routeParams: ParamMap) {
    const loggedUser = this.loggedUserService.getLoggedUserOrThrow();
    const documentIdParam = routeParams.get('customerDocumentId');
    const customerIdParam = routeParams.get('customerId');

    const filter: WorkListFilter<any, any> = this.createInitialWorkListFilter(loggedUser, documentIdParam, customerIdParam);
    this.initNewWorkList(filter);
  }

  createCustomerWorkListFilter(customerIdParam: string | null): WorkListFilter<any, any> {
    const loggedUser = this.loggedUserService.getLoggedUserOrThrow();

    return this.createInitialWorkListFilter(loggedUser, null, customerIdParam);
  }

  /**
   * Initialize a new work list and navigate to first document.
   * @param filter
   */
  initNewWorkList(filter: WorkListFilter<any, any>) {
    this.workListStateService.setWorkListFilter(filter);
    this.workListNavigationStateService.navigateToItemIndex(0);
  }

  /**
   * Navigate to the next item in the work list
   */
  gotToNextItem() {
    this.workListNavigationStateService.navigateToItemAtIndexDelta(+1);
  }

  /**
   * Navigate to the previous item in the work list
   */
  gotToPreviousItem() {
    this.workListNavigationStateService.navigateToItemAtIndexDelta(-1);
  }


  /**
   * Return the active navigation item, or undefined is no item can be navigated to (for instance,
   * when the work list is empty)
   */
  getWorkListItem$(): Observable<WorkListItem | undefined> {
    return this.workListNavigationStateService.getNavigationState$().pipe(
      map(state => state.activeItem),
      distinctUntilChanged((itemA, itemB) => itemA?.id === itemB?.id),
    );
  }

  getActiveWorkListFilter$(): Observable<WorkListFilter<any, any>> {
    return this.workListStateService.getWorkListState$().pipe(
      map(state => state?.workListFilter),
    );
  }

  /**
   * Create a work list fitler for a single document.
   * @param documentId
   */
  createSingleDocumentWorkListFilter(trusteeRef: WsRefWsTrustee | undefined, documentId: number): DocumentWorkListFilter {
    const loggedUser = this.loggedUserService.getLoggedUserOrThrow();
    if (trusteeRef == null && !loggedUser.nitroAdmin) {
      throw new Error(`No trustee in context`);
    }

    return {
      filter: {
        accountingDataSearch: {
          customerSearch: {
            trusteeSearch: {
              exactTrusteeWsRef: trusteeRef,
            }
          }
        },
        exactWsCustomerDocumentWsRef: {id: documentId}
      },
      sorts: [],
      workListType: 'document',
      label: `Document #${documentId}`,
      pageSize: 1
    };
  }

  createCustomerDocumentWorkList(trusteeRef: WsRefWsTrustee | undefined, customerId: number): DocumentWorkListFilter {
    const loggedUser = this.loggedUserService.getLoggedUserOrThrow();
    if (trusteeRef == null && !loggedUser.nitroAdmin) {
      throw new Error(`No trustee in context`);
    }

    return {
      filter: {
        accountingDataSearch: {
          customerSearch: {
            exactCustomerWsRef: {id: customerId},
            trusteeSearch: {
              exactTrusteeWsRef: trusteeRef,
            }
          },
          problematic: false,
        },
        anyStatus: [
          WsCustomerDocumentStatus.Sortable,
          WsCustomerDocumentStatus.Sorted,
        ]
      },
      sorts: [
        {field: WsCustomerDocumentSortField.CreationTime, order: 'asc'}
      ],
      workListType: 'document',
      label: `Documents du dossier #${customerId}`,
      pageSize: 32
    };
  }

  createCustomerFieldRequestWorkList(loggedUser: WsNitroUser, trusteeRef: WsRefWsTrustee | undefined, customerId: number): FieldRequestWorkListFilter {
    if (trusteeRef == null && !loggedUser.nitroAdmin) {
      throw new Error(`No trustee in context`);
    }
    return {
      filter: {
        anyStatus: [WsFieldIdentificationRequestStatus.WaitingForIndexing],
        needsNewIndexer: true,
        customerDocumentSearch: {
          accountingDataSearch: {
            customerSearch: {
              trusteeSearch: {
                exactTrusteeWsRef: trusteeRef,
              },
              exactCustomerWsRef: {id: customerId}
            },
          },
        },
        withoutAnyValueSearch: {
          anyStatus: [WsFieldIdentificationValueStatus.WaitingValidation, WsFieldIdentificationValueStatus.Submitted, WsFieldIdentificationValueStatus.Problem],
          indexerUserSearch: {
            exactNitroUserWsRef: {id: loggedUser.id!},
          },
        }
      },
      sorts: [
        {field: WsFieldIdentificationRequestSortField.DocumentCreationTime, order: 'asc'}
      ],
      workListType: 'fieldRequest',
      label: `Documents à indexer du dossier #${customerId}`,
      pageSize: 32
    };
  }

  createTrusteeDocumentWorkList(trusteeRef: WsRefWsTrustee | undefined): DocumentWorkListFilter {
    const loggedUser = this.loggedUserService.getLoggedUserOrThrow();
    if (trusteeRef == null && !loggedUser.nitroAdmin) {
      throw new Error(`No trustee in context`);
    }

    return {
      filter: {
        accountingDataSearch: {
          customerSearch: {
            trusteeSearch: {
              exactTrusteeWsRef: trusteeRef,
            }
          },
          problematic: false,
        },
        anyStatus: [
          WsCustomerDocumentStatus.Sortable,
          WsCustomerDocumentStatus.Sorted,
        ]
      },
      sorts: [
        {field: WsCustomerDocumentSortField.CreationTime, order: 'asc'}
      ],
      workListType: 'document',
      label: trusteeRef == null ? `Tous les documents` : `Documents de la fiduciaire`,
      pageSize: 32
    };
  }


  createTrusteeFieldRequestWorkList(loggedUser: WsNitroUser, trusteeRef: WsRefWsTrustee | undefined): FieldRequestWorkListFilter {
    if (trusteeRef == null && !loggedUser.nitroAdmin) {
      throw new Error(`No trustee in context`);
    }
    return {
      filter: {
        anyStatus: [WsFieldIdentificationRequestStatus.WaitingForIndexing],
        needsNewIndexer: true,
        customerDocumentSearch: {
          accountingDataSearch: {
            customerSearch: {
              trusteeSearch: {
                exactTrusteeWsRef: trusteeRef,
              },
            },
            problematic: false,
          },
        },
        withoutAnyValueSearch: {
          anyStatus: [WsFieldIdentificationValueStatus.WaitingValidation, WsFieldIdentificationValueStatus.Submitted, WsFieldIdentificationValueStatus.Problem],
          indexerUserSearch: {
            exactNitroUserWsRef: {id: loggedUser.id!},
          },
        }
      },
      sorts: [
        {field: WsFieldIdentificationRequestSortField.DocumentCreationTime, order: 'asc'}
      ],
      workListType: 'fieldRequest',
      label: `Documents à indexer`,
      pageSize: 32
    };
  }


  createIndexedFieldRequestWorkList(loggedUser: WsNitroUser, trusteeRef: WsRefWsTrustee | undefined): FieldRequestWorkListFilter {
    if (trusteeRef == null && !loggedUser.nitroAdmin) {
      throw new Error(`No trustee in context`);
    }

    return {
      filter: {
        customerDocumentSearch: {
          accountingDataSearch: {
            customerSearch: {
              trusteeSearch: {
                exactTrusteeWsRef: trusteeRef,
              },
            },
            problematic: false,
          },
        },
        withAnyValueSearch: {
          anyStatus: [WsFieldIdentificationValueStatus.WaitingValidation, WsFieldIdentificationValueStatus.Submitted, WsFieldIdentificationValueStatus.Problem],
          indexerUserSearch: {
            exactNitroUserWsRef: {id: loggedUser.id!},
          },
        }
      },
      sorts: [
        {field: WsFieldIdentificationRequestSortField.DocumentCreationTime, order: 'asc'}
      ],
      workListType: 'fieldRequest',
      label: `Mes documents (partiellement) indexés`,
      pageSize: 32
    };
  }

  createWorkListMenuOptions(loggedUser: WsNitroUser | undefined, trusteeRef: WsRefWsTrustee | undefined): SelectItem<WorkListFilter<any, any>>[] {
    if (loggedUser == null) {
      throw new Error(`No trustee/user in context`);
    }

    if (loggedUser?.nitroAdmin) {
      return [
        this.createFilterItem(this.createTrusteeDocumentWorkList(undefined)),
        this.createFilterItem(this.createIndexedFieldRequestWorkList(loggedUser, trusteeRef)),
      ];
    } else {
      return [
        this.createFilterItem(this.createTrusteeFieldRequestWorkList(loggedUser, trusteeRef)),
        this.createFilterItem(this.createIndexedFieldRequestWorkList(loggedUser, trusteeRef)),
      ];
    }
  }

  createInitialWorkListFilter(loggedUser: WsNitroUser, documentIdParam: string | null, customerIdParam: string | null): WorkListFilter<any, any> {
    const trusteeRef = this.loggedUserService.getActiveTrusteeRef();

    if (documentIdParam) {
      const docId = parseInt(documentIdParam);
      return this.createSingleDocumentWorkListFilter(trusteeRef, docId);
    }

    if (loggedUser.nitroAdmin) {
      // Admins work lists ignore field requests
      if (customerIdParam) {
        const customerId = parseInt(customerIdParam);
        return this.createCustomerDocumentWorkList(trusteeRef, customerId);
      } else {
        return this.createTrusteeDocumentWorkList(trusteeRef);
      }
    } else {
      // Indexer users have work lists based of field requests for which they could still submit values
      if (customerIdParam) {
        const customerId = parseInt(customerIdParam);
        return this.createCustomerFieldRequestWorkList(loggedUser, trusteeRef, customerId);
      } else {
        return this.createTrusteeFieldRequestWorkList(loggedUser, trusteeRef);
      }
    }

  }

  private createFilterItem(filter: WorkListFilter<any, any>): SelectItem<WorkListFilter<any, any>> {
    return {
      label: filter.label,
      value: filter
    };
  }

  removeCurrentItemAndNavigateNext(): void {
    this.getWorkListItem$().pipe(
      take(1),
      switchMap((currentItem: WorkListItem | undefined) => {
        if (currentItem && currentItem.id) {
          return this.workListStateService.removeItemFromWorkList(currentItem.id).pipe(
            map(() => currentItem),
        );
        }
        return EMPTY;
      })
    ).subscribe();
  }

}
